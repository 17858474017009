<template>
    <div v-if="me" class="edit-dating-preference">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('DATING_PREFERENCE')"
            :show-title="showHeaderTitle"
        />
        <main class="main" @scroll="onScroll">
            <div class="page-header">
                <div class="title" v-html="$translate('DATING_PREFERENCE')" />
            </div>
            <div
                class="content"
                v-for="(question, index) in preferences"
                :key="question.id"
                :class="index === 0 ? 'p-t-8' : ''"
            >
                <div class="question">Q. {{ question.title }} {{ question.multi_select ? '(복수선택)' : '' }}</div>
                <div
                    class="answer"
                    v-for="answer in question.dating_preference_answers"
                    :key="answer.id"
                    :class="myAnswer.includes(answer.id) ? 'selected' : ''"
                    @click="onClick(question.id, answer.id, question.multi_select)"
                    v-html="answer.title"
                />
                <template v-if="index === 0 && myAnswer.includes(3)">
                    <div class="info-title-container">
                        <img class="icon" src="@/assets/images/info.svg" />
                        <div class="info-title">유의해서 선택해주세요</div>
                    </div>
                    <div class="info-content" v-html="infoContent(me.gender)" />
                </template>
                <div :class="index === preferences.length - 1 ? 'm-b-40' : 'hr'" />
            </div>
        </main>
    </div>
</template>

<script>
import commonService from '@/services/common'

export default {
    name: 'EditDatingPreferencePage',
    data: () => ({
        preferences: [],
        myAnswer: [],
        datingPreferenceChanged: false,
        showHeaderTitle: false,
    }),
    watch: {
        me() {
            this.init()
        },
    },
    computed: {
        style() {
            return this.me.style || {}
        },
        me() {
            return this.$store.getters.me || {}
        },
    },
    methods: {
        async init() {
            this.preferences = await commonService.datingPreferences()
            this.myAnswer = (this.me.dating_preferences || []).map(p => p.dating_preference_answer_id)
        },
        async onClick(datingPreferenceId, datingPreferenceAnswerId, multiSelect) {
            const idx = this.myAnswer.indexOf(datingPreferenceAnswerId)
            if (idx >= 0 && !multiSelect) return
            const data = await commonService.userDatingPreferences({
                dating_preference_id: datingPreferenceId,
                dating_preference_answer_id: datingPreferenceAnswerId,
            })

            if (idx < 0) this.myAnswer.push(datingPreferenceAnswerId)
            if (data.deleted_id) {
                const idx = this.myAnswer.indexOf(data.deleted_id)
                this.myAnswer.splice(idx, 1)
            }

            this.datingPreferenceChanged = true
        },
        infoContent(gender) {
            if (gender === 0) {
                return this.$translate('DRINKING_WARNING_MALE')
            } else {
                return this.$translate('DRINKING_WARNING_FEMALE')
            }
        },
        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
    mounted() {
        this.$store.dispatch('loadMe')
        this.init()
    },
    beforeDestroy() {
        this.$store.dispatch('loadMe')
        if (this.datingPreferenceChanged) this.$toast.success('SAVED')
    },
}
</script>

<style lang="scss" scoped>
.edit-dating-preference {
    $header-height: 52px;
    overflow: hidden;

    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }

    .main {
        height: calc(100vh - #{$header-height});
        overflow-y: auto;
    }

    .content {
        padding: 0 16px;
    }
    .hr {
        margin: 28px 16px;
        background-color: $grey-01;
        height: 1px;
    }
    .question {
        font-size: 12px;
        color: $grey-06;
        margin-bottom: 4px;

        @include f-medium;
    }

    .answer {
        margin-top: 8px;
        padding: 10px 16px;
        border-radius: 8px;
        background-color: $grey-01;
        font-size: 14px;
        color: $grey-09;

        &.selected {
            color: white;
            background-color: $purple-primary;

            @include f-medium;
        }
    }

    .info-title-container {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        margin-bottom: 6px;
    }

    .info-title {
        font-size: 12px;
        color: $grey-09;
        margin-left: 6px;

        @include f-bold;
    }

    .info-content {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: -0.2px;
        color: $grey-09;
    }
}
</style>
